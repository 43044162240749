var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BasicInfo_wrapper" },
    [
      _c(
        "el-form",
        {
          ref: "baseForm",
          staticClass: "mt15",
          attrs: {
            rules: _vm.rules,
            model: _vm.baseInfo,
            "label-width": "130px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "隐私协议入口：", prop: "showAgreement" } },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "radio",
                  model: {
                    value: _vm.baseInfo.showAgreement,
                    callback: function($$v) {
                      _vm.$set(_vm.baseInfo, "showAgreement", $$v)
                    },
                    expression: "baseInfo.showAgreement"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v("用户报名页面展示《用户隐私协议》入口")
                  ]),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("用户报名页面隐藏《用户隐私协议》入口")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "入口文案：", prop: "buttonContent" } },
            [
              _c("el-input", {
                staticStyle: { width: "500px" },
                attrs: {
                  placeholder: "请输入文案，默认为用户隐私协议",
                  maxlength: "8",
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.baseInfo.buttonContent,
                  callback: function($$v) {
                    _vm.$set(_vm.baseInfo, "buttonContent", $$v)
                  },
                  expression: "baseInfo.buttonContent"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "40px" },
              attrs: { label: "用户隐私协议：", prop: "agreementContent" }
            },
            [
              _c("vue-ueditor-wrap", {
                attrs: { config: _vm.ueditorConfig },
                model: {
                  value: _vm.baseInfo.agreementContent,
                  callback: function($$v) {
                    _vm.$set(_vm.baseInfo, "agreementContent", $$v)
                  },
                  expression: "baseInfo.agreementContent"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitInfo } },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }