<template>
  <div class="BasicInfo_wrapper">
    <el-form ref="baseForm" :rules="rules" class="mt15" :model="baseInfo" label-width="130px">
      <el-form-item label="隐私协议入口：" prop="showAgreement">
        <el-radio-group v-model="baseInfo.showAgreement" class="radio">
          <el-radio :label="true">用户报名页面展示《用户隐私协议》入口</el-radio>
          <el-radio :label="false">用户报名页面隐藏《用户隐私协议》入口</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="入口文案：" prop="buttonContent">
        <el-input style="width: 500px" placeholder="请输入文案，默认为用户隐私协议" v-model="baseInfo.buttonContent" maxlength="8" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="用户隐私协议：" prop="agreementContent" style="margin-top: 40px">
        <vue-ueditor-wrap v-model="baseInfo.agreementContent" :config="ueditorConfig"></vue-ueditor-wrap>
      </el-form-item>
      <el-form-item>
        <el-button @click="submitInfo" type="primary">保 存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import ActivityAjax from "@/utils/https/modules/Activity.request.js";
  import VueUeditorWrap from "vue-ueditor-wrap";
  let ueditorurl = "";
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname.includes("192.168.") ||
    window.location.hostname.includes("admin-test.youxiangzb.com")
  ) {
    ueditorurl = "/UEditor/";
  } else {
    // ueditorurl = "https://admin.youxiangzb.com/admin/open/UEditor/";
    ueditorurl = "/UEditor/";
  }
  export default {
    name: "PrivacyProtocol", // 基本信息
    components: {
      VueUeditorWrap,
    },
    props: {
      activityID: String,
    },
    data() {
      return {
        rules: {
          agreementContent: [
            { required: true, message: "请输入用户隐私协议", trigger: "blur" },
          ],
          showAgreement: [
            { required: true, message: "请选择隐私协议入口", trigger: "blur" },
          ]
        },
        baseInfo: {
          showAgreement: true,
          buttonContent: ''
        },
        ueditorConfig: {
          // 编辑器不自动被内容撑高
          autoHeightEnabled: false,
          autoFloatEnabled: false,
          initialFrameWidth: 700,
          initialFrameHeight: 320,
          // UEDITOR_HOME_URL: `${process.env.VUE_APP_URL}/js/ueditor1_4_3_3-utf8-net/utf8-net/`
          UEDITOR_HOME_URL: ueditorurl,
        },
      };
    },
    computed: {},
    watch: {},
    methods: {
      // 获取用户协议
      async getActivityRule () {
        try {
          const {data} = await ActivityAjax.getActivityAgreement(this.activityID);
          if (data) {
            this.baseInfo = data
          }
        } catch (error) {
          this.$message.error(error);
        }
      },
      submitInfo() {
        let str = this.baseInfo.buttonContent
        if (str.includes(' ')) {
          return this.$message.error("入口文案不能有空格!!!");
        }
        this.$refs.baseForm.validate(async (valid) => {
          if (valid) {
            let params = { ...this.baseInfo }
            params.actId = parseInt(this.activityID)
            await ActivityAjax.postActivityAgreementSave(params);
            this.$message.success("保存成功");
          }
        });
      },
    },
    created() {},
    mounted() {
      this.getActivityRule()
    },
  };
</script>

<style lang="scss" scoped>
  .radio {
    display: flex;
    flex-direction: column;
    .el-radio {
      margin-top: 6px;
    }
  }
</style>
